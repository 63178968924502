<template>
  <el-dialog :model-value="dialogVisible" width="1010" class="klinecharts-dialog" @closed="closeDialog" @opened="openDialog">
    <el-tabs v-model="activeName" @tab-change="handleChange">
      <el-tab-pane :label="$t('K线图')" name="Key">
        <div class="charts-area">
          <div id="J_ChartKey"></div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('日线')" name="Day">
        <div class="charts-area">
          <div class="area-bg"></div>
          <div id="J_EchartDay" class="j_Echart"></div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('时线')" name="Hour">
        <div class="charts-area">
          <div class="area-bg"></div>
          <div id="J_EchartHour" class="j_Echart"></div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script setup>
import * as echarts from 'echarts/core'
import { TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import { KLineChartPro } from '@klinecharts/pro'
import { CustomDatafeed } from '~/api/customDatafeed'
import { getChart } from '~/api/steam'
import dayjs from 'dayjs'

import '@klinecharts/pro/dist/klinecharts-pro.css'
const { t } = useI18n()
const $t = t
const emit = defineEmits(['close'])
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  }
})
const activeName = ref('Key')
const chartData = ref([])

const handleChange = tab => {
  if (tab === 'Key') {
    // echartProInit()
  } else {
    getChartData(tab)
  }
}

const openDialog = () => {
  echartProInit()
}

const closeDialog = () => {
  emit('close')
}

// 获取日线和时线数据
const getChartData = tab => {
  chartData.value = []
  getChart({
    type: tab === 'Day' ? 2 : 1,
    maxTime: ''
  }).then(res => {
    res.data.forEach(item => {
      const time = dayjs(item[0] * 1000).format('YYYY-MM-DD HH:mm:ss')

      chartData.value.push([time, item[1]])
    })

    setTimeout(() => {
      echartInit(`J_Echart${tab}`)
    })
  })
}
const localeValue = useI18n().locale.value
// K线图初始化
const echartProInit = () => {
  const myKLineChart = new KLineChartPro({
    container: 'J_ChartKey',
    // 水印
    watermark: '',
    // 标的
    symbol: {
      shortName: $t('大盘指数'),
      priceCurrency: 'cny'
    },
    locale: localeValue === 'zh' ? 'zh-CN' : 'en-US',
    // 副图指标
    subIndicators: ['MACD'],
    // 当前周期
    period: { multiplier: 1, timespan: 'hour', text: $t('时K') },
    // 所有周期
    periods: [
      {
        multiplier: 1,
        timespan: 'hour',
        text: $t('时K')
      },
      {
        multiplier: 1,
        timespan: 'day',
        text: $t('日K')
      },
      {
        multiplier: 1,
        timespan: 'week',
        text: $t('周K')
      }
    ],
    // 数据接入api实现
    datafeed: new CustomDatafeed()
  })

  const red = '#F92855'
  const green = '#2DC08E'
  const alphaRed = 'rgba(249, 40, 85, .7)'
  const alphaGreen = 'rgba(45, 192, 142, .7)'
  console.log(1111, myKLineChart.getStyles())
  myKLineChart.setStyles({
    candle: {
      bar: {
        upColor: red,
        downColor: green,
        upBorderColor: red,
        downBorderColor: green,
        upWickColor: red,
        downWickColor: green
      },
      priceMark: {
        last: {
          upColor: red,
          downColor: green
        }
      },
      tooltip: {
        showType: 'standard',
        custom: function () {
          // 这个必须得用方法才能生效，直接用对象没效果
          return [
            { title: 'time', value: '{time}' },
            { title: 'open', value: '{open}' },
            { title: 'high', value: '{high}' },
            { title: 'low', value: '{low}' },
            { title: 'close', value: '{close}' }
            // { title: 'volume', value: '{volume}' }
          ]
        }
      }
    },
    indicator: {
      ohlc: {
        upColor: alphaRed,
        downColor: alphaGreen
      },
      bars: [
        {
          style: 'fill',
          borderStyle: 'solid',
          borderSize: 1,
          borderDashedValue: [2, 2],
          upColor: alphaRed,
          downColor: alphaGreen,
          noChangeColor: '#888888'
        }
      ],
      circles: [
        {
          style: 'fill',
          borderStyle: 'solid',
          borderSize: 1,
          borderDashedValue: [2, 2],
          upColor: alphaRed,
          downColor: alphaGreen,
          noChangeColor: '#888888'
        }
      ]
    }
  })
}

// 日线或时线初始化
const echartInit = id => {
  echarts.use([TooltipComponent, GridComponent, DataZoomComponent, LineChart, UniversalTransition, CanvasRenderer, SVGRenderer])

  const chartDom = document.getElementById(`${id}`)
  const myChart = echarts.init(chartDom, null, {
    renderer: 'svg'
  })

  const option = {
    color: ['#308651'],
    grid: {
      top: 20
    },
    tooltip: {
      trigger: 'axis'
    },
    textStyle: {
      fontSize: 14,
      color: '#979CAE'
    },
    xAxis: [
      {
        type: 'time',
        show: false,
        boundaryGap: false
      }
    ],
    yAxis: [
      {
        type: 'value',
        boundaryGap: [0, '10%'],
        splitLine: {
          lineStyle: {
            width: 1,
            color: '#EFF1F5'
          }
        }
      }
    ],
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
        zoomLock: true
      },
      {
        start: 0,
        end: 100,
        height: 50,
        bottom: 15,
        backgroundColor: '#F5F8FD',
        borderColor: '#EFF1F5',
        borderRadius: 0,
        dataBackground: {
          lineStyle: {
            width: 1,
            color: '#0252D9'
          },
          areaStyle: {
            color: '#EBEEFD',
            opacity: 1
          }
        },
        selectedDataBackground: {
          lineStyle: {
            width: 1,
            color: '#0252D9'
          },
          areaStyle: {
            color: '#EBEEFD',
            opacity: 1
          }
        },
        fillerColor: 'rgba(2, 82, 217, 0.2)',
        textStyle: {
          fontSize: 12,
          color: '#979CAE'
        },
        labelFormatter: function (val) {
          return dayjs(val).format('YY-MM-DD') + '\n' + dayjs(val).format('HH:mm:ss')
        },
        handleIcon: 'image://https://steamdt.com/sliderBar.svg',
        moveHandleSize: 0
      }
    ],
    series: [
      {
        type: 'line',
        smooth: false,
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 10,
        lineStyle: { width: 1 },
        areaStyle: {
          color: '#308651',
          opacity: 0.05
        },
        emphasis: {
          focus: 'series'
        },
        data: chartData.value
      }
    ]
  }

  option && myChart.setOption(option)
}
</script>

<style lang="scss">
@import url('~/assets/styles/_color.scss');

.klinecharts-dialog {
  .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
      width: 16px;
      height: 16px;
      line-height: 1;
      font-size: 16px;
      top: 16px;
      right: 16px;
      z-index: 10;
    }

    .el-dialog__close {
      width: 16px;
      height: 16px;
      line-height: 16px;
      font-family: 'iconfont';
      font-style: normal;

      &:hover {
        animation: loading 0.4s linear;
      }

      &::before {
        content: '\e6d2';
      }

      svg {
        display: none;
      }
    }
  }

  .el-dialog__body {
    padding: 0;

    .el-tabs {
      .el-tabs__header {
        margin: 0;
      }

      .el-tabs__active-bar {
        height: 0;
      }

      .el-tabs__nav-wrap::after {
        height: 1px;
      }

      .el-tabs__item {
        height: 60px;
        font-size: 16px;
        padding: 0 15px;
      }
    }

    .charts-area {
      height: 640px;
      background: $white-color;
      position: relative;

      .klinecharts-pro {
        height: 640px;
      }

      .area-bg {
        background: url('~/assets/images/detail/chart-bg.png') no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
      }

      .j_Echart {
        width: 1010px;
        height: 640px;
      }
    }
  }

  @keyframes loading {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
